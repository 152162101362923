var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-wrapper" },
    [
      _c("div", { staticClass: "header-line" }, [
        _c("div", { staticClass: "left-part" }, [
          _c(
            "div",
            { staticClass: "back-div" },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/go_back_icon.png"),
                  alt: "",
                },
              }),
              _c(
                "router-link",
                { attrs: { to: { path: "/workflow/list", query: _vm.query } } },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "border-line" }),
          _c("div", { staticClass: "name-div" }, [
            _c("span", { staticClass: "name-label" }, [
              _vm._v(" 发布记录 - " + _vm._s(_vm.title) + " "),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("div", { staticClass: "title" }, [_vm._v("发布记录")]),
            _vm._l(_vm.publishRecordList, function (item) {
              return _c("RecordBox", {
                key: item.versionId,
                staticClass: "record-box",
                class: {
                  selected: _vm.selectedRecord.versionId === item.versionId,
                },
                attrs: { data: item },
                on: {
                  click: function ($event) {
                    return _vm.handleSelectRecord(item)
                  },
                },
              })
            }),
          ],
          2
        ),
        _c("div", { ref: "rightRef", staticClass: "right" }, [
          _c(
            "div",
            { ref: "rightHeaderRef" },
            [
              _c(
                "div",
                { staticClass: "right-header" },
                [
                  _c("Segmented", {
                    attrs: { options: _vm.segmentedOptions },
                    on: { change: _vm.changeSegmented },
                    model: {
                      value: _vm.activeKey,
                      callback: function ($$v) {
                        _vm.activeKey = $$v
                      },
                      expression: "activeKey",
                    },
                  }),
                  _c("div", { staticStyle: { display: "inline-block" } }, [
                    _c(
                      "div",
                      { staticClass: "header-btns" },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "default-btn",
                            on: { click: _vm.handleRestore },
                          },
                          [_vm._v("还原")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "default-btn primary-btn",
                            attrs: { type: "primary" },
                            on: { click: _vm.handleCreateCopy },
                          },
                          [_vm._v("创建副本")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c("el-divider"),
            ],
            1
          ),
          _c("div", { staticClass: "process-graph" }, [
            _vm.showProcessGraph
              ? _c("div", [
                  _vm.processGraphHeight
                    ? _c(
                        "div",
                        [
                          _vm.activeKey === "workflowConfig"
                            ? _c("ProcessGraph", {
                                ref: "processGraphRef",
                                attrs: {
                                  bpmnId: _vm.selectedRecord.modeId,
                                  allowEdit: false,
                                  versionIdSpecial:
                                    _vm.selectedRecord.versionId,
                                  bHeight: _vm.processGraphHeight,
                                  isCompent: true,
                                  bpmnInitData:
                                    _vm.selectedRecord.versionDetail,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading",
                },
              ],
              staticClass: "dynamic-form",
            },
            [
              _vm.activeKey === "formConfig"
                ? _c("DynamicForm", {
                    attrs: {
                      editState: "readOnly",
                      dataset: _vm.selectedRecord.formConfig,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "flow-modal",
        {
          attrs: {
            width: "600px",
            title: "创建副本",
            visible: _vm.copyModalConfig.visible,
            loading: _vm.loading,
          },
          on: { onOk: _vm.onOkCopyModal, onCancel: _vm.onCancelCopyModal },
        },
        [
          _vm.copyModalConfig.visible
            ? _c("create-file-modal", {
                ref: "copyModalRef",
                attrs: {
                  path: _vm.copyModalConfig.data.path,
                  defaultForm: _vm.copyModalConfig.data.form,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }